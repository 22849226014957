<template>
  <div class="view-home">
      <div class="seminars-block">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><a href="/new-main">Главная</a></li>
              <li class="breadcrumbs-item">Мероприятия</li>
              <li class="breadcrumbs-item">Международные курсы повышения квалификации</li>
            </ul>
          </nav>
          <div class="seminars-title">
            Международные курсы повышения квалификации
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
          <div class="seminars-text">
            Курсы будут особенно интересны для сотрудников государственных учреждений и предприятий, национальных
            компаний, частных организаций, функционирующих в различных секторах. По результату прохождения курсов
            выдается сертификат организации, на базе которой проходило обучение. Дата проведения, длительность и
            стоимость полного пакета определяется индивидуально по запросу.
          </div>
        </div>
      </div>
    <div class="trainings-img-back">
      <div class="container">
        <div class="seminars-list-block">
          <div class="trainings-filters">
            <div class="filter-date date-icon">
              <el-select v-model="value1" placeholder="Дата проведения" class="filter-date-select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="filter-place">
              <el-select v-model="value2" placeholder="Место проведения" class="filter-date-select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="trainings-list">
            <div class="trainings-item">
              <div class="trainings-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img-2.png"
                    fit="fit"></el-image>
              </div>
              <div class="trainings-info">
                <div class="trainings-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="trainings-title">
                  Программа повышения квалификации Южного федерального университета «Технологии профессиональной
                  ориентации школьников»
                </div>
                <div class="trainings-text">
                  Особенности профориентационного сопровождения разных возрастных групп школьников и специфика работы с
                  детьми-инвалидами и детьми с ОВЗ в условиях общеобразовательной школы.
                </div>
                <div class="trainings-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="trainings-link">
                  Запросить подробную информацию
                </div>
              </div>
            </div>
            <div class="trainings-item">
              <div class="trainings-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img-2.png"
                    fit="fit"></el-image>
              </div>
              <div class="trainings-info">
                <div class="trainings-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="trainings-title">
                  Программа повышения квалификации Южного федерального университета «Технологии профессиональной
                  ориентации школьников»
                </div>
                <div class="trainings-text">
                  Особенности профориентационного сопровождения разных возрастных групп школьников и специфика работы с
                  детьми-инвалидами и детьми с ОВЗ в условиях общеобразовательной школы.
                </div>
                <div class="trainings-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="trainings-link">
                  Запросить подробную информацию
                </div>
              </div>
            </div>
            <div class="trainings-item">
              <div class="trainings-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img-2.png"
                    fit="fit"></el-image>
              </div>
              <div class="trainings-info">
                <div class="trainings-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="trainings-title">
                  Программа повышения квалификации Южного федерального университета «Технологии профессиональной
                  ориентации школьников»
                </div>
                <div class="trainings-text">
                  Особенности профориентационного сопровождения разных возрастных групп школьников и специфика работы с
                  детьми-инвалидами и детьми с ОВЗ в условиях общеобразовательной школы.
                </div>
                <div class="trainings-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="trainings-link">
                  Запросить подробную информацию
                </div>
              </div>
            </div>
            <div class="trainings-item">
              <div class="trainings-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img-2.png"
                    fit="fit"></el-image>
              </div>
              <div class="trainings-info">
                <div class="trainings-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="trainings-title">
                  Программа повышения квалификации Южного федерального университета «Технологии профессиональной
                  ориентации школьников»
                </div>
                <div class="trainings-text">
                  Особенности профориентационного сопровождения разных возрастных групп школьников и специфика работы с
                  детьми-инвалидами и детьми с ОВЗ в условиях общеобразовательной школы.
                </div>
                <div class="trainings-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <div class="trainings-link">
                  Запросить подробную информацию
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav class="seminars-pagination">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                      fill="black"/>
                </svg>
              </a>
            </li>
            <li class="page-item"><a class="page-link active" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                      fill="black"/>
                </svg>
              </a
              ></li>
          </ul>
        </nav>
      </div>
    </div>

    <el-dialog
        :visible.sync="dialogVisible"
        :width="modalWidthTrainings"
    >
      <div class="trainings-modal-head">
        <img src="/images/modal-train-img.png" alt="" width="48" height="48">
        Программа повышения квалификации Южного федерального университета «Технологии профессиональной ориентации школьников»
      </div>
      <div class="trainings-modal-title">
        Запрос подробной информации
      </div>
      <div class="trainings-modal-text">
        Оставьте свой E-mail для того что бы мы отправили вам подробную информацию о курсе
      </div>
      <label class="trainings-modal-email">
        E-mail
        <el-input placeholder="Email" v-model="input"></el-input>
      </label>
      <div class="trainings-modal-btns">
        <button class="trainings-modal-cancel">
          Отмена
        </button>
        <button  class="trainings-modal-request">
          Запросить информацию
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  mounted() {

  },
  filters: {

  },
  data() {
    return {
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      value1: '',
      value2: '',
      dialogVisible: true,
      modalWidthTrainings: "512px",
      input: "",
    }
  }
}
</script>
<style>

</style>
